$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

.delete-attendance-button {
  width: 2.2rem;
  aspect-ratio: 1 / 1;

  background-image: url("../../../../../public/img/gui/garbage_can_grey_01.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}

button {
  border: none;

  cursor: pointer;

  background: none;

  -webkit-tap-highlight-color: transparent;
}
