$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

.bottom-buttons-container {
  margin: 3rem 0 2rem 0;

  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;

  button {
    padding: 0.5rem 1rem;

    border-radius: 0.5rem;

    font-size: 1.2rem;

    cursor: pointer;

    color: #ffffff;
  }

  button.cancel {
    background-color: $grey;
  }

  button.submit {
    background-color: $teal;
  }
}
