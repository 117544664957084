$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$lighter-grey: #f1f1f1;
$grey: #3a3939;

/* Header */
.student-profile-header-container {
  margin-bottom: 0.4rem;
  padding: 0.5rem;

  display: grid;
  grid-auto-flow: column;
  align-content: center;
  grid-template-columns: auto auto 0.7rem;

  .status {
    justify-self: right;

    font-size: 1.3rem;
    font-weight: 500;
    color: #fff;
  }

  .more-info-container {
    justify-self: right;

    pointer-events: none;

    background-color: #ffffff;

    transform: rotate(45deg);

    margin-top: calc(-1rem - 0.5rem);
    margin-right: calc(-1rem - 0.5rem);

    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);

    grid-column: 3;
    grid-row: 1;

    width: 2rem;
    height: 2rem;
  }
}

/* Header - Status */
.archived {
  width: 1.5rem;
  background-image: url("./img/archived.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.pre-enrolled {
  background-color: $pre-enrolled;
}

.enrolled {
  background-color: $teal;
}

.short-absence {
  background-color: $red;
}

.long-absence {
  background-color: $grey;
}

.status-unknown {
  background-color: $grey;
}

/* Body - Name */
.student-profile-body-container {
  padding: 0.5rem;

  .name-container {
    margin-bottom: 1rem;

    .name-kanji-grade {
      font-size: 1.1rem;
      font-weight: 500;
    }

    .name-katakana {
      color: $grey;
      margin-left: 0.1rem;
    }

    .name-romaji {
      color: $grey;
      margin-left: 0.3rem;
    }
  }

  .student-info-container > div:not(:first-child) {
    border-top: solid 1px $light-grey;

    padding-top: 0.15rem;
  }

  .student-info-container > div:not(:last-child) {
    padding-bottom: 0.15rem;
  }
}

/* Footer */
.student-profile-footer-container {
  background-color: $lighter-grey;
  border-top: solid 1px $light-grey;

  padding: 0.8rem 1rem;

  display: grid;

  a.profile-edit-button {
    width: 1.8rem;
    height: 1.8rem;

    background-image: url("./img/edit.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }
}
