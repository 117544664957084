$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

#attendance-completed-indicator {
  position: fixed;

  bottom: 1rem;
  left: 0;

  padding: 0.5rem;

  background-color: rgba(248, 243, 212, 0.9);

  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;

  box-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.15);

  display: grid;
  place-items: center;
  grid-row-gap: 0.5rem;

  .completion-counter-text {
    width: calc(2.2rem + 1px);
    height: calc(2.2rem + 1px);

    box-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.15);

    border-radius: 50%;

    border: solid 2px #ffffff;

    display: grid;
    place-items: center;

    font-size: 1.2rem;

    &.record-count-incomplete {
      background-color: $pre-enrolled;
    }

    &.record-count-present {
      background-color: $teal;
    }

    &.record-count-absent {
      background-color: $red;
    }
  }
}

#attendance-completed-indicator.completed {
  background-color: rgba(0, 184, 169, 0.9);
}
