$grey: #3a3939;

.result-container {
  width: 4rem;
  display: grid;
  place-content: center;
  color: #fff;

  .result {
    font-size: 1.1rem;
    text-align: center;
  }
}

@media screen and (min-width: 450px) {
  .result-container {
    .result {
      font-size: 1.2rem;
    }
  }
}
