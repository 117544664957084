$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

#confirmation-modal-container {
  z-index: 101;

  position: fixed;

  top: 0;
  right: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);

  display: grid;
  place-items: center;

  .confirmation-modal-dialog-container {
    width: calc(100vw - 4rem);
    max-width: 350px;

    padding: 1rem;

    border: solid 1px $light-grey;
    border-radius: 0.3rem;

    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);

    background-color: #fff;

    display: grid;
    grid-row-gap: 1rem;
    justify-content: space-between;

    .confirmation-modal-dialog {
      grid-column: 1 / 3;

      place-self: center;
    }

    button {
      font-size: 1rem;

      padding: 0.6rem 1rem;

      color: #fff;

      border-radius: 0.3rem;
    }

    .cancel-button {
      background-color: $light-grey;

      justify-self: left;
    }

    .confirm-button {
      grid-column: 2;

      background-color: $red;

      justify-self: right;
    }
  }
}
