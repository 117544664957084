$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$grey: #3a3939;

h3 {
  margin-top: 3.75rem;
}

#attendance-toolbar {
  z-index: 3;
  position: fixed;
  top: 0;
  padding: 0.25rem 0.5rem;

  width: calc(100% - 1rem);
  height: calc(2.75rem - 0.5rem);

  background-color: $teal;
  box-shadow: 0 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;

  .toolbar-back-button {
    height: 1.5rem;
  }

  .button-set-container {
    display: grid;
    grid-auto-flow: column;

    .instructor-select-container {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 0.5rem;

      .instructor-button {
        width: 2rem;
        height: 2rem;

        border-radius: 0.2rem;

        cursor: pointer;

        background-repeat: no-repeat;
        background-position: 50% calc(100% + 1px);
        background-size: auto 95%;
      }

      .instructor-button:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }

      .instructor-button.active {
        background-color: #ffffff;
      }
    }
  }
}

/* prevents clicks to the toolbar before API call is complete */
#attendance-toolbar.disable-toolbar-buttons {
  pointer-events: none;
}
