$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

#attendance-create-section {
  #display-descriptors-container {
    font-size: 1.2rem;

    margin: 3.3rem 1rem 0 1rem;

    color: $grey;
    text-align: center;

    display: grid;
    justify-content: center;
  }

  .attendance-create-card-container {
    margin: 0.5rem auto 15rem auto;

    width: calc(100% - 2rem);
    max-width: 800px;

    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;

    .attendance-create-card {
      background-color: #ffffff;

      border: solid 1px $light-grey;
      border-radius: 0.3rem;

      box-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.15);

      overflow: hidden;

      .attendance-header-container {
        background: $teal;

        margin-bottom: 0.4rem;

        padding: 0.5rem;

        .attendance-name-text {
          color: #ffffff;
          font-size: 1.3rem;
          font-weight: 500;
        }

        .attendance-name-text::after {
          content: "\200B";
        }
      }

      .attendance-body-container {
        padding: 0 1rem;

        display: grid;

        .class-select-container {
          margin-top: 0.7rem;
        }

        label {
          margin-top: 0.7rem;
        }

        input:read-only,
        select:read-only {
          background-color: rgb(210, 210, 210);

          user-select: none;

          pointer-events: none;
        }

        #start-time-input.missing-input {
          outline: solid 2px $red;
        }

        .section-header {
          width: 100%;

          border-bottom: solid 1px $grey;

          color: $grey;
          text-align: right;

          margin-top: 2rem;
        }

        .label-and-data {
          display: grid;
          justify-content: left;
          align-items: end;
          grid-row-gap: 0.1rem;

          input,
          select {
            font-size: 1.2rem;
            font-weight: 500;
            color: $grey;

            border: solid 1px $grey;
            border-radius: 0.2rem;

            background-color: #ffffff;
          }

          select {
            padding-top: 3.5px;
            padding-bottom: 3.5px;
          }

          input:focus {
            outline: solid 2px $grey;
          }

          input[type="date"] {
            width: 9rem;

            background-color: rgb(210, 210, 210);

            pointer-events: none;
          }

          input::-webkit-calendar-picker-indicator {
            filter: invert(0.4);
          }
        }

        .label-and-data.instructor {
          grid-row: 4;

          select {
            background-color: rgb(210, 210, 210);
          }
        }

        .label-and-data.date {
          grid-row: 5;

          input {
            width: 12ch;
          }
        }

        .label-and-data.time {
          grid-row: 6;
        }
      }
    }
  }
}

@media screen and (min-width: 450px) {
  #attendance-create-section {
    .attendance-create-card-container {
      .attendance-create-card {
        .attendance-body-container {
          grid-template-columns: repeat(2, 1fr);

          .section-header {
            grid-column: 1 / 3;
          }

          #class-input {
            grid-column: 1 / 3;
          }

          .label-and-data.instructor {
            grid-row: 4;
          }

          .label-and-data.date {
            grid-row: 4;
          }

          .label-and-data.time {
            grid-row: 5;
          }

          .bottom-buttons-container {
            grid-column: 1 / 3;
          }
        }
      }
    }
  }
}
