$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$grey: #3a3939;

#student-profile-list-toolbar {
  z-index: 3;
  position: fixed;
  top: 0;
  padding: 0 0.5rem;

  width: calc(100% - 1rem);
  height: 2.75rem;

  background-color: $teal;
  box-shadow: 0 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);

  display: grid;
  justify-content: right;
  align-content: center;
  grid-auto-flow: column;

  #search-container {
    margin: 0 0.25rem;
    order: 4;

    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.5rem;

    #search-input {
      width: calc(100% - 2.6rem);
      height: 2rem;
      font-size: 1.2rem;
      border-radius: 0.3rem;
      border: none;
      padding: 0 0.5rem 0 2.1rem;
      color: $grey;

      background-image: url("./img/magnifying_glass.svg");
      background-repeat: no-repeat;
      background-position: 0.5rem 50%;
      background-size: auto 60%;
    }

    #search-input:focus {
      outline: $grey solid 2px;
    }

    #search-button {
      color: #fff;
      font-size: 1rem;
      border: solid 2px #fff;
      border-radius: 0.3rem;
    }
  }
}

/* prevents clicks to the toolbar */
#student-profile-list-toolbar.disable-toolbar-buttons {
  pointer-events: none;
}
