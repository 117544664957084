$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$grey: #3a3939;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border: none;
  cursor: pointer;
  background: none;

  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;

  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
  color: #000;
}

li {
  list-style-type: none;
}

/* Noto Sans JP 400, 500 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap");

body {
  font-family: "Noto Sans JP", sans-serif;
  background-color: #dad6d6;
}

input {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
}

ul,
li {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
}

a {
  -webkit-tap-highlight-color: transparent;
}

/* Content submitted */
.content-submitted {
  pointer-events: none;
  user-select: none;
  opacity: 0.6;
}

/* Cards - Responsive Columns */
.card-section {
  display: grid;
  margin-bottom: 10rem;
}

.card-container {
  margin: 0.5rem 1rem 1rem 1rem;

  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}

@media screen and (min-width: 650px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 975px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1300px) {
  .card-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1625px) {
  .card-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 2000px) {
  .card-container {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (min-width: 2500px) {
  .card-section {
    max-width: 2800px;
    margin: 0 auto 10rem auto;
  }

  .card-container {
    grid-template-columns: repeat(7, 1fr);
  }
}

.card {
  background-color: #fff;
  border: 1px solid $light-grey;
  border-radius: 0.3rem;
  box-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

/* Cards - Full Width */
.card-section-full-width {
  display: grid;
  justify-items: center;

  margin-bottom: 10rem;
}

.card-container-full-width {
  margin: 0.5rem auto 1rem auto;
  width: calc(100% - 2rem);
  max-width: 800px;

  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}

.card-full-width {
  background-color: #fff;
  border: 1px solid $light-grey;
  border-radius: 0.3rem;
  box-shadow: 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
