$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

button#auto-generate-attendance-records-button {
  width: 2rem;
  height: 2rem;

  border-radius: 0.3rem;

  background-image: url("../../../../../public/img/attendance/generate_data_white.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 90%;
}

button#auto-generate-attendance-records-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
