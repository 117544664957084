$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$grey: #3a3939;

.student-profile-footer-container {
  min-height: 1.8rem;
}

.confirm-delete-bottom-button-container {
  width: calc(100% - 2rem);
  max-width: 800px;

  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  .button {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    background-color: $grey;
    color: #fff;
  }

  .button.delete {
    background-color: $red;
  }
}
