$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$grey: #3a3939;

.create-new-profile-body-container {
  padding: 0 1.5rem;
}

#profile-create-form {
  display: grid;
  grid-auto-flow: row;

  label {
    margin-top: 0.7rem;
  }

  input {
    border: solid 1px #000;
    border-radius: 0.3rem;
    background-color: #fff;
    font-size: 1.2rem;
    padding: 0.3rem;

    -webkit-text-fill-color: #000;
  }

  input:focus {
    outline: $grey solid 2px;
  }

  /* form input widths */
  .input-width-7 {
    max-width: 7rem;
  }

  .input-width-s {
    max-width: 11rem;
  }

  .input-width-12 {
    max-width: 12rem;
  }

  .input-width-20 {
    width: calc(100% - 1rem);
    max-width: 20rem;
  }

  select {
    font-size: 1.2rem;
    border-radius: 0.3rem;
    padding: 0.3rem;
    cursor: pointer;
    border: solid 1px #000;

    -webkit-text-fill-color: #000;
  }

  select:focus {
    outline: $grey solid 2px;
  }

  .create-profile-section-header:first-child {
    margin-top: 0;
  }

  .create-profile-section-header {
    margin-top: 3rem;
  }

  .horizontal-select-menu {
    display: grid;
    grid-auto-flow: column;
    justify-content: left;

    button.horizontal-select-menu-option {
      font-size: 1rem;
      font-weight: 400;
      border: solid 1px #000;
      border-right: none;
      padding: 0.4rem 0.5rem;

      -webkit-text-fill-color: #000;
    }

    .horizontal-select-menu-option:last-child {
      border-right: solid 1px #000;
    }

    .selected-button {
      background-color: $teal;
    }
  }
  button#archived-button-container {
    height: 3rem;
    width: 5.8rem;
    border-radius: 3rem;
    padding: 0;
    background-color: $light-grey;
    transition: 350ms;
    display: grid;

    .archived-button-icon {
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      margin: 0.75rem 1rem;
      background-image: url("img/archived.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .archived-dot {
      position: relative;
      top: 0.25rem;
      left: 0.25rem;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      transition: 350ms;
      background-color: #fff;
    }
  }

  button#archived-button-container.archived-button-active {
    background-color: $yellow;

    .archived-dot {
      transform: translateX(2.8rem);
    }
  }

  #phone-number-container {
    display: grid;
    grid-auto-flow: row;

    .phone-number-data {
      display: grid;
      grid-auto-flow: row;

      margin-top: 1.25rem;
      padding: 0 3rem 0.75rem 0.75rem;
      background-color: $beige;
      justify-self: left;
      border-radius: 0.5rem;

      .phone-number {
        width: calc(100% - 1rem);
      }
    }

    .add-remove-phone-number-container {
      margin-top: 1.5rem;

      display: grid;
      justify-content: left;
      grid-auto-flow: column;
      grid-column-gap: 2rem;

      button {
        padding: 0;
        background-color: $light-grey;
        width: 1.9rem;
        height: 1.9rem;
        border-radius: 50%;

        display: grid;
        grid-template-columns: 1.5fr 2fr 1fr 2fr 1.5fr;
        grid-template-rows: 1.5fr 2fr 1fr 2fr 1.5fr;
      }

      button.disable-button {
        opacity: 0.4;
        pointer-events: none;
      }

      .vertical-line {
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        grid-column: 3;
        grid-row: 2 / 5;
        background-color: #fff;
      }

      .horizontal-line {
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        grid-column: 2 / 5;
        grid-row: 3;
        background-color: #fff;
      }
    }
  }

  .bottom-buttons-container {
    margin: 3rem 0 2rem 0;

    display: grid;
    justify-content: space-between;
    grid-auto-flow: column;

    .button-group-container {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 1rem;
    }

    .button[type="submit"] {
      background-color: $teal;
      color: #fff;
    }

    .button {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      background-color: $grey;
      color: #fff;
    }

    .button.delete {
      background-color: $red;
    }
  }
}

@media screen and (min-width: 450px) {
  button.horizontal-select-menu-option {
    padding: 0.4rem 1rem;
  }
}
