$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

html,
body {
  scroll-behavior: smooth;
}

#calendar-container {
  scroll-snap-type: x mandatory;

  width: 100%;
  overflow: scroll;

  padding-bottom: 15rem;

  display: grid;
  grid-auto-flow: column;

  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  .day-of-week-container {
    // scroll-snap-align: start;

    padding-top: 3.5rem;

    padding-bottom: 1rem;

    display: grid;

    .background-and-events-container {
      width: calc(100% - 1rem);

      margin: 0 0.5rem;

      position: relative;

      display: grid;

      .background-container {
        box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);

        border: solid 1px $light-grey;
        border-radius: 0.5rem;

        .hour-increment {
          border-bottom: solid 1px $grey;

          height: 11rem; /* match CSS and JS values */

          background-color: $beige;

          display: grid;
          align-items: end;

          .hour-text {
            font-style: italic;
            font-size: 0.8rem;

            margin: 0 0 0.3rem 0.3rem;
          }
        }

        .hour-increment:first-child {
          border-radius: 0.5rem 0.5rem 0 0;
        }

        .hour-increment:last-child {
          border-radius: 0 0 0.5rem 0.5rem;
        }

        .hour-increment:last-child {
          border-bottom: none;
        }
      }

      .events-container {
        width: calc(100% - 1rem);

        position: absolute;
        top: 0;

        justify-self: center;

        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        grid-column-gap: 0.5rem;

        .instructor-container {
          width: 100%;

          position: relative;

          display: grid;

          .event {
            position: absolute;

            background-color: #fff;

            width: 100%;

            box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);

            outline: solid 1px $light-grey;
            border-radius: 0.5rem;

            display: grid;
            grid-template-rows: auto 1fr;

            .event-header {
              cursor: pointer;

              color: rgb(30, 30, 30);

              background-color: $teal;

              font-size: 0.6rem;

              padding: 0.25rem 0.3rem;

              border-radius: 0.5rem 0.5rem 0 0;

              display: grid;

              overflow: hidden;

              .more-info-container {
                justify-self: right;

                pointer-events: none;

                background-color: #ffffff;

                transform: rotate(45deg);

                margin-top: calc(-0.25rem - 1rem);
                margin-right: calc(-0.3rem - 1rem);

                box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);

                grid-column: 2;
                grid-row: 1;

                width: 2rem;
                height: 2rem;
              }

              .event-name {
                grid-column: 1 / 3;
                grid-row: 1;

                font-size: 1.1rem;
              }

              .event-start-time {
                font-size: 1rem;

                align-self: center;
              }

              .student-count-container {
                grid-column: 2;
                grid-row: 2;
                justify-self: right;
                align-self: center;

                padding: 0 0.2rem;

                font-size: 0.8rem;

                border-radius: 0.2rem;

                background-color: $yellow;

                color: rgb(50, 50, 50);

                display: grid;
                grid-auto-flow: column;
              }

              .event-full {
                background-color: #f87292;
              }
            }

            .event-body {
              padding: 0.2rem;

              margin: 0.2rem 0;

              overflow-x: hidden;
              overflow-y: scroll;

              display: grid;
              align-items: start;
              align-content: start;
              grid-row-gap: 0.2rem;

              cursor: pointer;

              .student-container {
                cursor: pointer;

                display: grid;
                justify-content: left;
                align-items: center;
                grid-column-gap: 0.5rem;

                .student-status {
                  grid-row: 1 / 3;

                  width: 1.2rem;
                  height: 1.2rem;

                  border-radius: 50%;
                  border: solid 0.15rem #fff;

                  box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
                }

                .pre-enrolled {
                  background-color: $pre-enrolled;
                }

                .enrolled {
                  background-color: $teal;
                }

                .short-absence {
                  background-color: $red;
                }

                .long-absence {
                  background-color: $grey;
                }

                .student-name-kanji {
                  font-size: 0.9rem;

                  grid-column: 2;
                }

                .student-name-katakana {
                  grid-column: 2;

                  font-size: 0.7rem;

                  display: grid;
                  justify-content: left;
                  grid-column-gap: 0.2rem;
                  grid-auto-flow: column;
                }
              }
            }

            .event-instructor-icon {
              position: absolute;

              width: 2rem;
              height: 2rem;

              // border: solid 0.1rem #fff;
              // border-radius: 50%;

              // box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);

              right: 0.15rem;
              bottom: 0rem;

              background-repeat: no-repeat;
              background-position: 50% calc(100% + 0.1rem);
              background-size: contain;
            }
          }

          .event.highlighted-event {
            outline: solid 2px $red;
          }

          .duplicate {
            z-index: 2;

            transform: translate(0, 1.65rem);

            .event-header {
              background-color: $pre-enrolled;

              color: #000;
            }
          }
        }
      }
    }
  }

  .day-of-week-container:last-child {
    .background-and-events-container {
      border-right: none;
    }
  }
}

#indicator-container {
  z-index: 3;

  pointer-events: none;

  position: fixed;
  top: 4.25rem;

  width: 100%;

  opacity: 0.9;

  display: grid;
  justify-items: center;

  #indicator-box {
    pointer-events: auto;

    display: grid;
    grid-column-gap: 0.375rem;
    grid-auto-flow: column;

    .indicator {
      width: 1.5rem;
      aspect-ratio: 1 / 1;

      border-radius: 50%;

      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;

      border: solid 2px #fff;

      -webkit-tap-highlight-color: transparent;

      cursor: pointer;

      user-select: none;

      box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);

      background-color: $yellow;

      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 5fr 95fr;
      place-items: center;
    }

    .indicator::before {
      content: "";
    }

    .active {
      background-color: $teal;

      color: #fff;
    }
  }
}

#next-previous-navigation-container {
  z-index: 3;

  pointer-events: none;

  position: fixed;
  top: calc(50% - (5rem / 2));

  margin: 0 1.5rem;

  width: calc(100% - 3rem);

  display: none;
  grid-auto-flow: column;
  justify-content: space-between;

  button {
    width: 2.5rem;
    aspect-ratio: 1 / 1;

    border-radius: 50%;
    border: solid 0.15rem #fff;

    pointer-events: auto;

    box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);

    background-color: $light-grey;

    opacity: 0.9;

    background-image: url(./img/prev_next_arrow_white.svg);
    background-size: 40% auto;
    background-position: 55% 50%;
    background-repeat: no-repeat;

    transition-duration: 200ms;
  }

  .disabled {
    pointer-events: none;

    opacity: 0;
  }

  #previous-day-button {
    transform: rotate(180deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

#confirmation-modal-container {
  z-index: 101;

  position: fixed;

  top: 0;
  right: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);

  display: grid;
  place-items: center;

  .confirmation-modal-dialog-container {
    width: calc(100vw - 4rem);
    max-width: 350px;

    padding: 1rem;

    border: solid 1px $light-grey;
    border-radius: 0.3rem;

    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);

    background-color: #fff;

    display: grid;
    grid-row-gap: 1rem;
    justify-content: space-between;

    .confirmation-modal-dialog {
      grid-column: 1 / 3;

      place-self: center;
    }

    button {
      font-size: 1rem;

      padding: 0.6rem 1rem;

      color: #fff;

      border-radius: 0.3rem;
    }

    .cancel-button {
      background-color: $light-grey;

      justify-self: left;
    }

    .confirm-button {
      grid-column: 2;

      background-color: $red;

      justify-self: right;
    }
  }
}

/* disables previous next day navigation buttons on touch screen devices */
@media (hover: hover) {
  #next-previous-navigation-container {
    display: grid;
  }
}

/* width queries */
@media screen and (min-width: 350px) {
  #calendar-container {
    .day-of-week-container {
      .background-and-events-container {
        .events-container {
          .instructor-container {
            .event {
              .event-header {
                // .more-info-container {
                //   margin-top: calc(-0.25rem - 0.75rem);
                //   margin-right: calc(-0.3rem - 0.75rem);

                //   width: 1.5rem;
                //   height: 1.5rem;
                // }
              }
              .event-body {
                padding: 0.2rem 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 400px) {
  #calendar-container {
    .day-of-week-container {
      .background-and-events-container {
        .events-container {
          .instructor-container {
            .event {
              .event-header {
                // .more-info-container {
                //   margin-top: calc(-0.25rem - 0.8rem);
                //   margin-right: calc(-0.3rem - 0.8rem);

                //   width: 1.6rem;
                //   height: 1.6rem;
                // }

                // .event-name {
                //   font-size: 0.7rem;
                // }

                // .event-start-time {
                //   font-size: 0.7rem;
                // }

                // .student-count-container {
                //   .student-count-text-element {
                //     font-size: 0.7rem;
                //   }
                // }
              }
              .event-body {
                .student-container {
                  // .student-status {
                  //   width: 12px;
                  //   height: 12px;
                  // }

                  // .student-name-kanji {
                  //   font-size: 0.6rem;
                  // }

                  // .student-name-katakana {
                  //   font-size: 0.55rem;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }

  #indicator-container {
    #indicator-box {
      grid-column-gap: 0.5rem;

      .indicator {
        width: 2rem;

        font-size: 1rem;

        grid-template-columns: 2fr 98fr;
      }
    }
  }
}

@media screen and (min-width: 450px) {
  #calendar-container {
    .day-of-week-container {
      .background-and-events-container {
        .background-container {
          .hour-increment {
            height: 15.35rem;
          }
        }
        .events-container {
          .instructor-container {
            .event {
              .event-header {
                // .more-info-container {
                //   margin-top: calc(-0.25rem - 0.85rem);
                //   margin-right: calc(-0.3rem - 0.85rem);

                //   width: 1.7rem;
                //   height: 1.7rem;
                // }

                // .event-name {
                //   font-size: 0.85rem;
                // }

                // .event-start-time {
                //   font-size: 0.85rem;
                // }

                // .student-count-container {
                //   .student-count-text-element {
                //     font-size: 0.8rem;
                //   }
                // }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 500px) {
  #calendar-container {
    .day-of-week-container {
      .background-and-events-container {
        .events-container {
          .instructor-container {
            .event {
              .event-header {
                // .more-info-container {
                //   margin-top: calc(-0.25rem - 0.9rem);
                //   margin-right: calc(-0.3rem - 0.9rem);

                //   width: 1.8rem;
                //   height: 1.8rem;
                // }

                // .event-name {
                //   font-size: 0.9rem;
                // }

                // .event-start-time {
                //   font-size: 0.85rem;
                // }

                // .student-count-container {
                //   .student-count-text-element {
                //     font-size: 0.8rem;
                //   }
                // }
              }

              .event-body {
                .student-container {
                  grid-row-gap: 0;

                  // .student-status {
                  //   width: 15px;
                  //   height: 15px;

                  //   border-width: 2px;
                  // }

                  // .student-name-kanji {
                  //   font-size: 0.8rem;
                  // }

                  // .student-name-katakana {
                  //   font-size: 0.7rem;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  #calendar-container {
    .day-of-week-container {
      .background-and-events-container {
        .events-container {
          .instructor-container {
            .event {
              .event-header {
                // .more-info-container {
                //   margin-top: calc(-0.25rem - 0.95rem);
                //   margin-right: calc(-0.3rem - 0.95rem);

                //   width: 1.9rem;
                //   height: 1.9rem;
                // }

                // .event-name {
                //   font-size: 1rem;
                // }

                // .event-start-time {
                //   font-size: 1rem;
                // }

                // .student-count-container {
                //   .student-count-text-element {
                //     font-size: 1.05rem;
                //   }
                // }
              }

              .event-body {
                .student-container {
                  // .student-name-kanji {
                  //   font-size: 0.9rem;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 700px) {
  #calendar-container {
    .day-of-week-container {
      .background-and-events-container {
        .events-container {
          .instructor-container {
            .event {
              .event-header {
                // .more-info-container {
                //   margin-top: calc(-0.25rem - 1rem);
                //   margin-right: calc(-0.3rem - 1rem);

                //   width: 2rem;
                //   height: 2rem;
                // }

                // .event-name {
                //   font-size: 1.1rem;
                // }
              }
              .event-body {
                .student-container {
                  // .student-status {
                  //   width: 18px;
                  //   height: 18px;
                  // }

                  // .student-name-kanji {
                  //   font-size: 1rem;
                  // }

                  // .student-name-katakana {
                  //   font-size: 0.8rem;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  #calendar-container {
    .day-of-week-container {
      .background-and-events-container {
        .events-container {
          .instructor-container {
            .event {
              .event-body {
                .student-container {
                  // .student-status {
                  //   width: 20px;
                  //   height: 20px;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  #calendar-container {
    .day-of-week-container {
      // width: calc(100vw / 2);

      .background-and-events-container {
        .events-container {
          // width: calc(calc(100% / 2) - 2rem);
          width: calc(100% - 1rem);

          .event {
            .event-body {
              .student-container {
                // .student-status {
                //   width: 18px;
                //   height: 18px;
                // }

                // .student-name-kanji {
                //   font-size: 0.9rem;
                // }

                // .student-name-katakana {
                //   font-size: 0.8rem;
                // }
              }
            }
          }
        }
      }
    }
  }
}
