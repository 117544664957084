$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

.class-select-container {
  padding: 0.5rem;

  border-radius: 0.3rem;

  background: $yellow;

  display: grid;
  grid-row-gap: 0.5rem;

  .event-number-indicator {
    grid-column: 2;

    justify-self: right;

    font-size: 0.85rem;
  }

  .event-search {
    grid-column: 1 / 3;

    width: calc(100% - 0.6rem);
    border-radius: 0.3rem;

    padding: 0.3rem;

    background-color: #ffffff;

    border: none;
  }

  .event-search:focus {
    outline: solid 2px $medium-grey;
  }

  .event-select-container {
    grid-column: 1 / 3;

    height: 25rem;

    background-color: #ffffff;

    border-radius: 0.3rem;

    overflow-y: scroll;
    overflow-x: hidden;

    display: grid;
    align-content: start;

    .event-container {
      padding: 0 0.75rem 1rem 0.75rem;

      border-bottom: solid 1px $medium-grey;

      user-select: none;

      font-size: 0.85rem;

      cursor: pointer;

      display: grid;
      grid-template-columns: calc(2rem + 4px) auto;
      grid-column-gap: 0.5rem;
      align-items: start;

      .event-day-time-container {
        grid-column: 1 / 3;

        margin-bottom: 0.5rem;

        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;

        .day-of-week-box {
          background-color: #ffffff;

          grid-row: 1;

          border: solid 1px $grey;
          border-top: none;
          border-right: none;

          display: grid;
          place-content: center;
        }

        .day-of-week-box:nth-child(1) {
          border-bottom-left-radius: 0.3rem;
        }

        .day-of-week-box:nth-child(7) {
          border-bottom-right-radius: 0.3rem;
        }

        .start-time-box {
          grid-row: 2;

          width: calc(100% - 1px);

          background-color: $yellow;

          border-bottom-right-radius: 0.3rem;
          border-bottom-left-radius: 0.3rem;
          border: solid 1px $grey;
          border-top: none;

          padding-bottom: 0.1rem;

          display: grid;
          place-content: center;
        }

        .start-time-box.saturday {
          width: calc(100% - 2px);
        }

        .day-of-week-box:nth-child(7) {
          border-right: solid 1px $grey;
        }

        .day-of-week-box.active {
          background-color: $yellow;

          border-bottom: none;
        }

        .day-of-week-box.active.saturday {
          border-bottom-right-radius: 0;
        }

        .day-of-week-box.active.sunday {
          border-bottom-left-radius: 0;
        }
      }

      .event-primary-instructor {
        grid-column: 1;
        grid-row: 2 / 4;

        align-self: center;

        height: 1.75rem;
        width: 1.75rem;

        background-color: $teal;

        border-radius: 50%;
        border: solid 2px #ffffff;

        box-shadow: 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.4);

        background-repeat: no-repeat;
        background-position: 50% calc(100% + 0.1rem);
        background-size: auto 90%;
      }

      .event-name {
        grid-column: 2 / 3;

        align-self: center;
        justify-self: left;

        font-size: 1rem;

        padding-bottom: 0.1rem;
      }

      .event-type {
        grid-column: 2;

        font-size: 0.75rem;
      }

      .event-students-container {
        grid-column: 1 / 3;

        margin-top: 0.5rem;

        display: grid;
        justify-content: right;
        justify-items: left;

        .event-student {
          font-size: 0.8rem;

          white-space: nowrap;

          display: grid;
          grid-auto-flow: column;
          grid-column-gap: 0.4rem;

          .status-indicator {
            place-self: center;

            width: 0.75rem;
            height: 0.75rem;

            place-self: center;

            border-radius: 50%;
            border: solid 2px #fff;
            box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
          }

          .pre-enrolled {
            background-color: $pre-enrolled;
          }

          .enrolled {
            background-color: $teal;
          }

          .short-absence {
            background-color: $red;
          }

          .long-absence {
            background-color: $grey;
          }
        }
      }
    }

    .event-container.active {
      background-color: $teal;

      .event-name,
      .event-type,
      .event-students-container {
        font-weight: 500;
        color: rgb(30, 30, 30);
      }

      .event-name {
        font-weight: 400;
      }
    }

    .event-container:last-child {
      border-bottom: none;
    }

    .event-container > div {
      pointer-events: none;
    }

    .loading-spinner-container {
      margin-top: 0;
    }
  }
}

.class-select-container.missing-input {
  outline: solid 2px $red;
}
