$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

#attendance-records {
  .attendance-records-container {
    display: grid;

    .attendance-records-header-container {
      background-color: $light-grey;

      padding: 0.5rem;

      display: grid;
      grid-auto-flow: column;
      align-content: center;
      justify-content: space-between;

      .attendance-records-title,
      .attendance-records-number {
        color: #ffffff;

        font-size: 1.3rem;
        font-weight: 500;
      }

      &.pre-enrolled {
        background-color: $pre-enrolled;
      }

      &.enrolled {
        background-color: $teal;
      }

      &.short-absence {
        background-color: $red;
      }

      &.long-absence {
        background-color: $grey;
      }
    }

    .attendance-records-body-container {
      max-height: 500px;

      overflow-y: scroll;

      padding: 0.5rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0.4rem;

      display: grid;
      grid-row-gap: 0.5rem;

      .record-container {
        padding: 0.5rem;

        border: solid 1px $light-grey;
        border-radius: 0.5rem;

        box-shadow: 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.2);

        cursor: pointer;

        display: grid;
        grid-column-gap: 0.75rem;
        grid-template-columns: auto 1.5rem;

        .record-data-container {
          pointer-events: none;
          user-select: none;

          justify-content: left;

          font-size: 0.8rem;

          display: grid;
          grid-auto-flow: row;
          grid-column-gap: 1rem;
          grid-template-columns: 2rem auto;

          .instructor-icon {
            grid-row: 1 / 3;

            width: 2rem;
            height: 2rem;

            border-radius: 50%;
            border: solid 2px #ffffff;

            box-shadow: 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.4);

            background-color: $teal;
            background-repeat: no-repeat;
            background-position: 50% calc(100% + 0.1rem);
            background-size: auto 90%;
          }

          .attendance-date {
            grid-column: 2;
            grid-row: 1;
          }

          .attendance-start-time {
            grid-column: 2;
            grid-row: 2;
          }

          .attendance-event-name {
            grid-column: 2;
            grid-row: 3;
          }

          .attendance-record-grade-name {
            grid-column: 2;
            grid-row: 4;
          }
        }

        .attendance-record-status-icon {
          pointer-events: none;
          user-select: none;

          justify-self: right;
          align-self: center;

          width: 1.5rem;
          height: 1.5rem;

          border-radius: 50%;
          border: solid 2px #ffffff;

          box-shadow: 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.4);

          background-repeat: no-repeat;
          background-size: 55% auto;
          background-position: 50% 50%;

          &.pending {
            background-color: $pre-enrolled;
            background-image: url("../../../../public/img/attendance/pending_white.svg");
          }

          &.present {
            background-color: $teal;
            background-image: url("../../../../public/img/attendance/present_white.svg");
          }

          &.absent {
            background-color: $red;
            background-image: url("../../../../public/img/attendance/absent_white.svg");
          }
        }
      }
    }
  }
}

@media screen and (min-width: 350px) {
  #attendance-records {
    .attendance-records-container {
      .attendance-records-body-container {
        .record-container {
          padding: 0.75rem;

          grid-template-columns: auto 1.75rem;

          .record-data-container {
            font-size: 0.9rem;

            grid-template-columns: 2.5rem auto;

            .instructor-icon {
              width: 2.5rem;
              height: 2.5rem;
            }
          }

          .attendance-record-status-icon {
            width: 1.75rem;
            height: 1.75rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 400px) {
  #attendance-records {
    .attendance-records-container {
      .attendance-records-body-container {
        .record-container {
          padding: 1rem;

          grid-column-gap: 1.25rem;

          .record-data-container {
            grid-auto-flow: column;
            grid-column-gap: 1.25rem;

            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  #attendance-records {
    .attendance-records-container {
      .attendance-records-body-container {
        .record-container {
          grid-auto-flow: column;
          justify-content: space-between;
          align-items: center;
          grid-column-gap: 1.5rem;

          .record-data-container {
            font-size: 1rem;

            grid-auto-flow: column;
            grid-column-gap: 1.5rem;
            grid-template-columns: 2.5rem 10rem auto;

            .attendance-event-name {
              grid-column: 3;
              grid-row: 1;
            }

            .attendance-record-grade-name {
              grid-column: 3;
              grid-row: 2;
            }
          }
        }
      }
    }
  }
}
