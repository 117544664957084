$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

#attendance-student-container.student-container {
  grid-column: 1 / 3;

  .student-number-indicator {
    grid-column: 2;

    background-color: $yellow;

    padding: 0 0.2rem;

    border-radius: 0.2rem;

    font-size: 0.85rem;

    justify-self: right;
  }

  .student-select-container {
    margin-top: 0.5rem;
    padding: 0.5rem;

    border-radius: 0.3rem;

    background-color: $yellow;

    display: grid;
    grid-row-gap: 0.5rem;

    .student-search {
      grid-column: 1 / 3;

      width: calc(100% - 0.6rem);

      font-size: 1rem;

      border: none;
      border-radius: 0.3rem;

      background-color: #ffffff;

      padding: 0.3rem;

      -webkit-text-fill-color: #000000;
    }

    .student-search:focus {
      outline: solid 2px $medium-grey;
    }

    #select-container {
      grid-column: 1 / 3;

      background-color: #ffffff;

      height: 15rem;

      overflow-x: hidden;
      overflow-y: scroll;

      border-radius: 0.3rem;

      display: grid;
      align-content: start;

      .student-selected {
        background-color: $teal;

        border-bottom: 1px solid rgba(0, 0, 0, 0);

        pointer-events: none;

        .add-student-icon {
          opacity: 0;
        }
      }

      .student-selected:last-child {
        border-bottom: none;
      }

      .loading-spinner-container {
        margin-top: 0;
      }
    }

    .student-status-indicator {
      grid-column: 1;
      grid-row: 1 / 3;

      width: 1.1rem;
      aspect-ratio: 1 / 1;

      place-self: center;

      border-radius: 50%;
      border: solid 2px #fff;
      box-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2);
    }

    .student-name-container {
      padding: 0.2rem 0.3rem;

      border-bottom: solid 1px $light-grey;

      cursor: pointer;

      display: grid;
      grid-template-columns: 2rem auto 2rem;

      .student-name-kanji {
        grid-column: 2;

        align-self: center;

        font-size: 0.9rem;

        pointer-events: none;
      }

      .student-name-katakana {
        align-self: center;

        pointer-events: none;

        font-size: 0.7rem;
      }

      .add-student-icon {
        place-self: center;

        grid-column: 3;
        grid-row: 1 / 3;

        margin: 0.3rem 0;

        width: 1.5rem;
        aspect-ratio: 1 / 1;

        pointer-events: none;

        border-radius: 50%;

        background-color: $teal;

        user-select: none;

        background-image: url("../../../../../public/img/gui/plus_white.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 60% auto;
      }
    }

    .student-name-container.no-katakana {
      grid-template-rows: auto 0;
    }

    .student-name-container.no-kanji {
      grid-template-rows: 0 auto;
    }

    .student-name-container:last-child {
      border-bottom: none;
    }
  }
}
