$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

#attendance {
  margin-top: 3.75rem;
  margin-bottom: 15rem;

  width: 100%;

  display: grid;
  justify-items: center;
}
