$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$grey: #3a3939;
$light-grey: #adadad;
$lighter-grey: #f1f1f1;

$font-size: 1.2rem;
$font-size-small: 1rem;
$indented-padding-left: 2rem;
$indented-padding-left-small: 1rem;
$padding-right: 2rem;
$padding-right-small: 1rem;

$glass-blur: blur(0.4rem);
$glass-opacity: 0.9;

.filter-menu-container {
  z-index: 1;
  user-select: none;
  position: absolute;

  background-color: rgba(255, 255, 255, $glass-opacity);
  backdrop-filter: $glass-blur;
  -webkit-backdrop-filter: $glass-blur;

  border-radius: 0.3rem;
  box-shadow: 0 0 0.3rem 0.3rem rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(255, 255, 255, 1);

  max-height: calc(100vh - 5rem);
  overflow: scroll;

  display: grid;

  .filter-main-title {
    padding: 0.6rem 1rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .clear-all-filters-button {
    grid-column: 2;

    place-self: center;

    color: #fff;
    font-size: 1rem;
    background-color: $red;
    border-radius: 0.3rem;
    font-weight: 500;
    padding: 0.3rem 0.5rem;
  }

  .clear-all-filters-button:hover {
    background-color: rgba(246, 65, 108, 0.7);
  }

  .filter-title-container {
    grid-column: 1 / span 2;

    padding: 0.6rem $padding-right 0.6rem $indented-padding-left;
    font-size: $font-size;

    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.5rem;
    align-items: center;
    justify-content: left;

    .section-title {
      font-weight: 400;
      -webkit-text-fill-color: #000;
    }
  }

  .filter-title-container:hover {
    background-color: rgba(255, 222, 125, 0.7);
  }

  .filter-title-arrow {
    background-image: url("./img/arrow_small.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 0.75rem;
    width: 0.75rem;
  }

  .filter-title-arrow.rotate-90-deg {
    transform: rotate(90deg);
  }

  .filter-menu-group {
    grid-column: 1 / span 2;

    border-top: solid 1px rgba(0, 0, 0, 0.25);

    display: grid;

    .select-all-none-container {
      margin: 0.6rem $padding-right 0.6rem $indented-padding-left;

      white-space: nowrap;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: column;
      grid-column-gap: 1.5rem;

      justify-items: center;

      button {
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        grid-column-gap: 0;
        color: #000;
        background-color: rgba(255, 222, 125, 1);
        border-radius: 0.3rem;
        font-weight: 400;
        margin: 0;
      }

      button:hover {
        background-color: rgba(255, 222, 125, 0.7);
      }
    }

    button {
      padding: 0.6rem $padding-right 0.6rem $indented-padding-left;
      font-size: $font-size;

      display: grid;
      grid-template-columns: 1.2rem auto;
      grid-template-rows: 1.2rem;
      grid-auto-flow: column;
      grid-column-gap: 1rem;
      justify-content: left;
      align-items: center;

      input[type="checkbox"] {
        width: 100%;
        height: 100%;

        background-color: $grey;
      }

      label {
        cursor: pointer;
        font-weight: 400;
        -webkit-text-fill-color: #000;
      }
    }

    button:hover {
      background-color: rgba(255, 222, 125, 0.7);
    }
  }

  .filter-menu-group.height-zero-overflow-hidden {
    height: 0;
    overflow: hidden;
    border-top: 0;
  }
}

.close-filter-menu-background-button {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

@media screen and (min-width: 576px) {
  .filter-menu-container {
    .filter-main-title {
      font-size: $font-size-small;
      padding: 0.4rem 0.5rem;
    }

    .clear-all-filters-button {
      font-size: 0.9rem;
      padding: 0.2rem 0.4rem;
    }

    .filter-title-container {
      font-size: $font-size-small;
      padding: 0.4rem $padding-right-small 0.4rem $indented-padding-left-small;
    }

    .filter-menu-group {
      button {
        font-size: $font-size-small;
        padding: 0.4rem $padding-right-small 0.4rem $indented-padding-left-small;

        grid-template-columns: 1rem;
        grid-template-rows: 1rem;
      }

      .select-all-none-container {
        margin: 0.4rem $padding-right-small 0.4rem $indented-padding-left-small;
        grid-column-gap: 0.75rem;

        button {
          font-size: $font-size-small;
        }
      }
    }
  }
}
