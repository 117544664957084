$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$grey: #3a3939;

#student-profile-delete-toolbar {
  z-index: 3;
  position: fixed;
  top: 0;
  padding: 0 0.5rem;

  width: calc(100% - 1rem);
  height: 2.75rem;

  background-color: $teal;
  box-shadow: 0 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);

  display: grid;
  justify-content: left;
  align-content: center;
  grid-auto-flow: column;
}
