$teal: #00b8a9;
$beige: #f8f3d4;
$red: #f6416c;
$yellow: #ffde7d;
$deep-yellow: #ffcb31;

$pre-enrolled: #fdbc00;
$light-grey: #adadad;
$medium-grey: #5f5f5f;
$grey: #3a3939;

#attendance-container {
  max-width: 800px;
  width: 100%;

  padding: 1rem 0;

  display: grid;
  grid-row-gap: 1rem;
  justify-items: center;

  .card {
    background-color: #ffffff;
    border: solid 1px $medium-grey;
    border-radius: 0.2rem;
    box-shadow: 0.2rem 0.2rem 0.4rem rgb(0 0 0 / 15%);
  }

  .attendance {
    position: relative;

    padding: 0.75rem;

    width: calc(100% - 2.5rem);

    display: grid;
    grid-row-gap: 0.5rem;
    grid-template-columns: calc(2.5rem + 4px) auto;
    grid-column-gap: 1rem;

    .more-info-container {
      position: absolute;

      top: calc(-1.15rem);
      right: calc(-1.15rem);

      background-color: $light-grey;

      cursor: pointer;

      transform: rotate(45deg);

      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);

      width: 2.2rem;
      height: 2.2rem;
    }

    .primary-instructor-icon {
      width: 2.5rem;
      height: 2.5rem;

      border-radius: 50%;
      border: solid 2px #ffffff;

      background-color: $teal;

      box-shadow: 0.1rem 0.2rem 0.2rem rgb(0 0 0 / 40%);

      background-position: 50% calc(100% + 0.1rem);
      background-repeat: no-repeat;
      background-size: auto 90%;
    }

    .section-title-container {
      cursor: pointer;

      grid-column: 2;

      width: 100%;

      border-bottom: solid 1px #000000;

      font-size: 1.2rem;

      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;

      .class-name {
        grid-row: 1 / 3;
      }
    }

    .section-title-container > div {
      pointer-events: none;
    }

    .attendance-records-container {
      grid-column: 1 / 3;

      padding: 1rem;

      font-size: 1.2rem;

      display: grid;
      grid-row-gap: 1rem;

      .attendance-record {
        display: grid;
        grid-template-columns: auto 3rem;

        .student-name-kanji {
          cursor: pointer;

          grid-row: 1;
          grid-column: 1;
        }

        .student-name-katakana {
          cursor: pointer;

          font-size: 0.8rem;
        }

        .student-attendance-status {
          grid-row: 1 / 3;

          justify-self: right;
          align-self: center;

          background-repeat: no-repeat;
          background-size: 55% auto;
          background-position: 50% 50%;

          cursor: pointer;

          border-radius: 50%;
          border: solid 2px #ffffff;

          box-shadow: 0.2rem 0.2rem 0.4rem rgb(0 0 0 / 15%);

          width: calc(2rem + 1px);
          height: calc(2rem + 1px);
        }

        .student-attendance-status.pending {
          background-color: $pre-enrolled;
          background-image: url("../../../../public/img/attendance/pending_white.svg");
        }

        .student-attendance-status.present {
          background-color: $teal;
          background-image: url("../../../../public/img/attendance/present_white.svg");
        }

        .student-attendance-status.absent {
          background-color: $red;
          background-image: url("../../../../public/img/attendance/absent_white.svg");
        }

        .disable-clicks-to-attendance-status-buttons {
          pointer-events: none;
        }
      }
    }
  }

  .schedule-break-container {
    padding: 0.5rem;

    background-color: $yellow;

    font-size: 1.1rem;
    font-weight: 400;

    width: calc(100% - 2rem);

    display: grid;
    place-content: center;
  }
}
